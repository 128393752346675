
  .SpacesTechTitle {
    text-shadow: 0px 0px 7px white;
  }
  .supportMessage {
    display: none ;
  }
  .sponsorIcon {
    filter: "drop-shadow(0px 0px 4px #FFFFFF40)",
  }
  @media (max-width: 285px) {
    .SpacesTechTitle {
     font-size: 2rem;
    }
  
    .iconImage {
      max-width: 300px;
    }
  }

@media only screen and (min-width: 1280px) and (max-width: 1650px) {
  .aiWorkshopMainContainer .spaceIcon1 {
    width: 500px;
    height: 500px;
  }
  .aiWorkshopMainContainer .spaceIcon2 {
    width: 500px;
    height: 500px;
  }
  .aiWorkshopContent .mobileGridItem {
    position: relative;
  }
  .aiWorkshopTitles .secondTitleaiWorkshopOrange {
    font-size: 2.5rem;
  }
  .aiWorkshopTitles .mainTitleaiWorkshopWhite {
    font-size: 2.5rem;
    max-width: 600px;
  }
  .mobileGPTIcon {
    display: none;
  }
  .mobileLogoSection .mobileLogoSection2 {
    display: none;
  }
  .chatGPTIcon {
    width: 340px;
    height: 340px;
    margin-bottom: 1rem;
  }
  .sessionTitle {
    font-size: 2rem;
  }
  .sponsorIcon {
    height: 80px;
  }
  .sponsorIcon2 {
    height: 80px;
  }

  .aiWorkshopMainContainer {
    height: auto;
  }
  .descriptionaiWorkshop {
    font-size: 24px;
  }
  .descriptionaiWorkshop2 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .spaceIcon1 {
    display: none;
  }
  .spaceIcon2 {
    display: none;
  }
.aiWorkshop {
  margin-top: 5%;
} 
.img {
  z-index: 1;
  position: relative;
  max-width: 23rem;
  }
.supportMessage {
  display: block ;
}
.CTAButton {
  display: block;
  margin: auto;
  background-color: #F29322 ;
  width: 195px;
  height: 36px;
  font-size: 15px ;
  text-align: center;
}
.buttonTextContainer {
  font-size: 14px;
  margin-top: 2%;
}
.aiWorkshopContent {
  display: block ;
  padding: 0rem ;
  width: 100% ;
  text-align: center;
}
.aiWorkshopContent .mobileGridItem {
  max-width: 100% ;
}
.mobileLogoSection .mobileGridItem {
  max-width: 100%;
}
.chatGPTIcon {
  display: none;
}
.aiWorkshopContainer .mobileGPTIcon {
  display: block;
  margin: auto;
  width: 300px;
  height: 300px;
  margin-top: 5rem;
}
.mobileLogoSection {
  display: block;
}
.logoSection2 {
  padding-left: 0% ;
}

.aiWorkshopContent .mobileGridItem {
  margin: auto;
}
.sessionDate {
  display: flex;
  justify-content: center;
  margin: 1%;
}
.borderClass {
  margin: auto ;
  margin-top: 2% ;
  margin-bottom: 0rem;
}
.disclaimerAI {
  margin: 4%;
}
.mobileGridItem .sponsorIcon {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 2%;
  height: 120px;
}
.mobileGridItem .sponsorIcon2 {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 120px;
}
.aiWorkshopContent .sponsorMessage {
  display: none;
}
.mobileGridItem .sponsorMessageMobile {
  margin-top: 1rem;
  text-align: center;
  display: block;
}
.aiWorkshopTitles .mainTitleaiWorkshopWhite {
  margin-top: 10%;
}
.aiWorkshopContent .descriptionaiWorkshop2 {
  margin: auto;
}
.mobileLogoSection .logoSection2 {
  display: none;
}
.mobileLogoSection2 {
  margin-top: 1rem;
}
}

@media (max-width: 600px) {
  .spaceIcon {
      display: none;
  }
  .spaceIcon1 {
    display: none;
  }
  .spaceIcon2 {
    display: none;
  }
  .aiWorkshop {
    margin-top: 5%;
  }
  .img {
  z-index: 1;
  position: relative;
  max-width: 23rem;
  }
  .aiWorkshopContent .sponsorMessage {
    display: none;
  }
  .mobileGridItem .sponsorMessageMobile {
    margin-top: 1rem;
    text-align: center;
    display: block;
  }
  .supportMessage {
    display: block ;
  }
  .CTAButton {
    display: block;
    margin: auto;
    background-color: #F29322 ;
    width: 195px;
    height: 36px;
    font-size: 15px;
    text-align: center;
  }
  .buttonTextContainer {
    font-size: 14px;
    margin-top: 2%;
  }
  .aiWorkshopContent {
    display: block;
    padding: 0rem;
    width: 100%;
    text-align: center;
  }
  .aiWorkshopContent .mobileGridItem {
    max-width: 100%;
  }
  .mobileLogoSection .mobileGridItem {
    max-width: 100%;
  }
  .chatGPTIcon {
    display: none;
  }
  .aiWorkshopContainer .mobileGPTIcon {
    margin: auto;
    display: block;
    width: 250px;
    height: 250px;
    margin-top: 5rem;
  }
  .mobileLogoSection {
    display: block ;
  }
  .logoSection2 {
    padding-left: 0% ;
  }
  .borderClass {
    margin-bottom: 0rem;
    margin-top: 5%;
  }
  .mobileGridItem .sponsorIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 3%;
    height: 120px;
  }
  .mobileGridItem .sponsorIcon2 {
    display: flex;
    justify-content: center;
    margin: auto;
    height: 120px;
  }
  .sponsorMessage {
    margin-top: 1rem;
  }
  .mobileTitle {
    max-width: 500px;
    margin: auto;
  }
  .sessionDate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2%;
  }
  .disclaimerAI {
    margin: 4%;
  }
  .aiWorkshopTitles .mainTitleaiWorkshopWhite {
    margin-top: 10%;
  }
  .mobileLogoSection .logoSection2 {
    display: none;
  }
  .mobileLogoSection2 {
    margin-top: 1rem;
  }
}

@media (min-width: 1650px) {
  .aiWorkshopMainContainer {
    height: auto;
  }
  .aiWorkshopTitles .secondTitleaiWorkshopOrange {
    font-size: 3rem;
  }
  .aiWorkshopTitles .mainTitleaiWorkshopWhite {
    font-size: 3rem;
    max-width: 700px;
  }
  .mobileLogoSection .mobileLogoSection2 {
    display: none;
  }
  .mobileGPTIcon {
    display: none;
  }
  .chatGPTIcon {
    width: 370px;
    height: 370px;
    margin-bottom: 1rem;
  }
  .mobileTitle .sessionTitle {
    font-size: 2rem;
  }
  .mobileGridItem .sponsorIcon {
    height: 100px;
  }
  .mobileGridItem .sponsorIcon2 {
    height: 100px;
  }
  .logoItem {
    max-width: 50%;
    flex-basis: 45%;
  }
  .aiWorkshopContent .mobileGridItem {
    position: relative;
  }
  .sponsorSection {
    position: relative;
    margin-top: .5rem;
  }

  .buttonTextContainer .CTAButton {
    font-size: 24px;
    background-color: #F29322;
  }
}

@media (max-width: 1280px) and (max-height: 600px) {
  .aiWorkshopMainContainer {
    height: auto;
  }

}

@media (min-width: 960px) and (max-width: 1280px) {
  .aiWorkshopMainContainer .spaceIcon1 {
    width: 400px;
    height: 500px;
  }
  .aiWorkshopMainContainer .spaceIcon2 {
    width: 400px;
    height: 500px;
  }
  .aiWorkshopContent .mobileGridItem {
    position: relative;
  }
  .aiWorkshopMainContainer {
    height: auto;
  }
  .mobileGPTIcon {
    display: none;
  }
  .chatGPTIcon {
    width:250px;
    height:250px;
    margin-bottom: 4%;
  }
  .mobileGridItem .descriptionaiWorkshop {
    font-size: 20px;
  }
  .mobileGridItem .descriptionaiWorkshop2 {
    font-size: 18px;
    max-width: 500px;
  }
  .mobileGridItem .sponsorMessage {
    font-size: 18px;
  }
  .mobileLogoSection {
    margin: .5rem 0rem;
  }
  .sponsorMessage2 {
    text-align: center;
  }
  .mobileLogoSection .mobileLogoSection2 {
    display: none;
  }
}

@media (min-width: 1280px) and (max-height: 900px) {
  .aiWorkshopMainContainer {
    height:auto;
  }
  .aiWorkshopTitles .secondTitleaiWorkshopOrange {
    font-size: 2rem;
  }
  .aiWorkshopTitles .mainTitleaiWorkshopWhite {
    font-size: 2rem;
    max-width: 530px;
  }
  .chatGPTIcon {
    width: 320px;
    height: 320px;
    margin-bottom: 1rem;
  }
  .buttonTextContainer .descriptionaiWorkshop2 {
    font-size: 20px;
  }
}

@media (min-width: 960px) and (max-height: 768px) {

  .mobileGridItem .chatGPTIcon {
    width: 280px;
    height: 280px;
    margin-bottom: 1rem;
  }
  .buttonTextContainer .descriptionaiWorkshop2 {
    font-size: 15px;
  }
}