.signin-section {
    background-image: url(../../assets/images/SignInUP/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow-y: clip;
    min-height: 780px;
}


.signin-container {
    
    height: 100%;
    max-height: 800px;
    background: white;
    border-radius: 50px;
    flex-wrap: nowrap;
    box-shadow: 10px -3px 20px rgba(0, 0, 0, 0.75);
    min-height: 620px;
}

.signin-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    max-width: 1250px;
}

.signin-form {
    margin-top: 1rem;
    width: 100%;
    padding: 0 2rem;
}

.container-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    max-width: 1250px;
}
.sign-in-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signupSection-container {
    background-image: url(../SignUp/rightSideBackground.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 0px 50px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #FF824D;
    
}
.kino-logo-container {
    width: 100%;
}

.kino-logo-container img {
   border-radius: 50px 0 0 0;
}

.kino-logo-container img {
    width: 100%;
}
.not-a-member {
    text-align: center;
}
@media (min-width: 0px) and (max-width: 600px) {
    .kino-logo-container img {
        border-radius: 50px;
    }
    .signinTitle {
        font-size: 2rem !important;
    }
    .signin-container{
        height: auto;
    }
    .signin-content {
        height: 100vh;
        width: 100%;
        padding: 1rem;
    }
    .signupSection-container{
        display: none;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin: .5rem;
    }
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    .kino-logo-container img {
        border-radius: 50px;
    }
    .signinTitle {
        font-size: 2rem !important;
    }
    .signin-content {
        height: 100vh;
        padding: 1rem;
        display: block;
    }
    .signin-container{
        height: auto;
    }
    .signupSection-container{
        display: none;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin: .5rem;
    }
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
}

@media (min-width: 960px) and (max-width: 1280px) {
    .signin-container {
        
        margin-top: 4%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: auto;
        
    }
    .signinTitle {
        font-size: 2rem !important;
    }
    .signupSection-container {
        height: 100vh;
        flex-basis: 100% !important;
        
    }
    .takeMeInDescription {
        margin: 3%;
    }
    .container-content {
        height: 90vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin-top: 1%;
    }
}

@media (min-width: 1280px) and (max-width: 1650px) {
    .signin-container {
        overflow-y: clip;
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: auto;
        
    }

    .signupSection-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 93vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signin-form {
        margin-top: 0%;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin-top: 1%;
    }
}

@media (min-width: 1650px) and (max-width: 1920px){
    .signin-container {
        
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: auto;
        
    }

    .signupSection-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 93vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signin-form {
        margin-top: 0%;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin-top: 2%;
    }

}

@media (min-width: 1920px) and (min-height: 600px) and (max-height: 750px) {
    .signin-container {
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: 100vh;
        max-width: 139vh;
        width: 80vw;
    }

    .signin-container{
        margin-top:0;
        height: 81.2vh;
        min-height: 550px;
        max-width: 150vh;
    }

    .signupSection-container {
        height: 81.2vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 81.2vh;
        margin: 0;
        flex-direction: column;
        max-width: 139vh;
    }
    .form-conatiner {
        height: 81.2vh;
        flex-basis: 100% !important;
    }

    .kino-logo-container {
        width: 100%;
        height: 22vh;
    }

    .kino-logo-container img {
        border-radius: 50px 0 0 0;
        width: 72vh;
        height: 22vh;
    }
    #mainTitle {
        color: #032836;
        font-size: 5.4vh;
        text-align: center;
    }
    .signin-content #mainDescr {
        color: #032836;
        font-size: clamp(14px, 2vh, 2vh);
        text-align: center;
    }
    .makeStyles-container_forgot-49 {
        font-size: clamp(16px, 2vh, 2vh);
    }
    .sign-in-alternate-auth {
        gap: 2rem;
        display: flex;
        padding: 8px;
        align-items: center;
        width: 13vw;
        justify-content: center;
    }


    .auth-provider-sign-in-page {
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        border: 3px solid #0e6688;
        text-align: center;
        background: #fff;
        color: #535a60;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 14px 28px rgba(0, 0, 0, .06), 0 10px 10px rgba(0, 0, 0, .11);
        height: 5vh;
        width: 5vh;
    }
    .auth-provider-sign-in-page svg {
        height: 2.8vh;
        width: 2.8vh;
    }

    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signin-form {
        margin-top: 0%;
        width: 80%;
    }
    .signin-content .socialNetworkTitle {
        text-align: center;
        font-size: clamp(16px,1.6vh,1.6vh);
    }
    .MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        font-size: 2vh;
        font-weight: 900;
    }
    .MuiFormControl-root .MuiOutlinedInput-root {
        position: relative;
        border-radius: 20px;
        font-size: 2vh;
        background-color: #EAEFF3;
    }

    .makeStyles-container-48 .makeStyles-button-57 {
        font-size: 20px;
        border-radius: 20px;
        color: var(--grey);
        width:195px;
        max-width: 195px;
        height: 36px;
        box-shadow: none;
        margin-top: .5rem;
        margin-left: 8px;
        margin-right: 8px;
        background-color: var(--darkColor);
    }


    .signin-content .termsDescriptionsMobile{
        color: gray;
        width: 100%;
        font-size: clamp(12px, 1.1vh, 1.1vh);
        margin-top: 5rem;
        text-align: center;
        font-weight: 600;
    }

    .signupSection-container .makeStyles-takeMeInTitle-381 {
        color: #f29322;
        font-size: 5.6vh;
        margin-top: 3.5rem;
        text-align: center;
        margin-bottom: 8rem;
    }
    .signupSection-container .makeStyles-takeMeInDescription-43{
        color: #B8C1C5;
        font-size: 3vh;
        text-align: center;
        margin-bottom: 4rem;
    }
    .signupSection-container .makeStyles-signInButton-44 {
        color: #ffff;
        width: 100%;
        font-size: 16px !important;
        max-width: 195px;
        height: 36px;
        margin-top: 3rem;
        font-weight: 900 !important;
        background-color: #f29322;
    }
    .already-a-member {
        margin-top: 2%;
        font-size: clamp(16px, 1.5vh, 1.5vh);
    }
}

@media (min-width: 1920px) and (min-height: 750px) {
    .signin-container {
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: 100vh;
        max-width: 139vh;
        width: 80vw;
    }

    .signin-container{
        margin-top:0;
        height: 81.2vh;
        min-height: 0;
        max-width: 139vh;
    }

    .signupSection-container {
        height: 81.2vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 81.2vh;
        margin: 0;
        flex-direction: column;
        max-width: 139vh;
    }
    .form-conatiner {
        height: 81.2vh;
        flex-basis: 100% !important;
    }

    .kino-logo-container {
        width: 100%;
        height: 22vh;
    }

    .kino-logo-container img {
        border-radius: 50px 0 0 0;
        width: 72vh;
        height: 22vh;
    }
    #mainTitle {
        color: #032836;
        font-size: 5.4vh;
        text-align: center;
    }
    .signin-content #mainDescr {
        color: #032836;
        font-size: clamp(14px, 2vh, 2vh);
        text-align: center;
    }
    .makeStyles-container_forgot-49 {
        font-size: clamp(16px, 2vh, 2vh);
    }
    .sign-in-alternate-auth {
        gap: 2rem;
        display: flex;
        padding: 8px;
        align-items: center;
        width: 13vw;
        justify-content: center;
    }


    .auth-provider-sign-in-page {
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        border: 3px solid #0e6688;
        text-align: center;
        background: #fff;
        color: #535a60;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 14px 28px rgba(0, 0, 0, .06), 0 10px 10px rgba(0, 0, 0, .11);
        height: 5vh;
        width: 5vh;
    }
    .auth-provider-sign-in-page svg {
        height: 2.8vh;
        width: 2.8vh;
    }

    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signin-form {
        margin-top: 0%;
        width: 80%;
    }
    .signin-content .socialNetworkTitle {
        text-align: center;
        font-size: clamp(16px, 1.6vh, 1.6vh);
    }
    .MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        font-size: 2vh;
        font-weight: 900;
    }
    .MuiFormControl-root .MuiOutlinedInput-root {
        position: relative;
        border-radius: 20px;
        font-size: 2vh;
        background-color: #EAEFF3;
    }

    .signin-form .makeStyles-button-57 {
        font-size: 22px;
        border-radius: 20px;
        color: var(--grey);
        max-width: 244px;
        height: 45px;
        box-shadow: none;
        margin-top: .5rem;
        margin-left: 8px;
        margin-right: 8px;
        background-color: var(--darkColor);
    }

    .signin-content .termsDescriptionsMobile{
        color: gray;
        width: 100%;
        font-size: clamp(12px, 1.1vh, 1.1vh);
        margin-top: 5rem;
        text-align: center;
        font-weight: 600;
    }

    .signupSection-container .makeStyles-takeMeInTitle-381 {
        color: #f29322;
        font-size: 5.6vh;
        margin-top: 3.5rem;
        text-align: center;
        margin-bottom: 8rem;
    }
    .signupSection-container .makeStyles-takeMeInDescription-43{
        color: #B8C1C5;
        font-size: 3vh;
        text-align: center;
        margin-bottom: 4rem;
    }
    .signupSection-container .makeStyles-signInButton-44 {
        color: #ffff;
        width: 100%;
        font-size: 22px !important;
        width: 244px;
        height: 45px;
        max-width: 244px;
        margin-top: 3rem;
        font-weight: 900 !important;
        background-color: #f29322;
    }
    .already-a-member {
        font-size: clamp(16px, 1.5vh, 1.5vh);
        margin-top: 2%;
    }
}