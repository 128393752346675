.container-content{
    min-height: 750px;
}
.signup-section{
    background-image: url(../../assets/images/SignInUP/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    min-height: 857px;
}
.form-conatiner{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.signup-form {
    margin-top: 1rem;
    width: 100%;
    padding: 0 2rem;
}

.signup-container {
    height: 100%;
    max-height: 800px;
    background: white;
    border-radius: 50px;
    flex-wrap: nowrap;
    box-shadow: 10px -3px 20px rgba(0, 0, 0, 0.75);
}

.signup-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    max-width: 1250px;
    padding-bottom: 30px;
}
.already-a-member {
    text-align: center
}

.take-me-in-container {
    background-image: url(../SignUp/rightSideBackground.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 0px 50px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #FF824D;
    
}

.kino-logo-container {
    width: 100%;
}

.kino-logo-container img {
   border-radius: 50px 0 0 0;
}

.kino-logo-container img {
    width: 100%;
}

.sign-up-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bg-image {
    position: absolute;
    width: 100%;
    bottom: 28%;
    z-index: -10;
}

.kino-logo-container-small img {
    width: 100%;
    border-radius: 50px;
}


.MuiInputLabel-formControl {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 1px;
}


@media (min-width: 0px) and (max-width: 600px) {
    .kino-logo-container img {
        border-radius: 50px;
    }
    .signupTitle {
        font-size: 2rem !important;
    }
    .signup-form {
        margin-top: 0rem;
    }
    .authProviderSignup {
        height: 3rem !important;
        width: 3rem !important;
    }
    .signup-content {
        height: 100vh; 
        width: 100%;
        padding: 1rem;
        
    }
    .signup-container {
        height: auto;
    }
    .container-content {
        height: auto;
    }
    .already-a-member {
        margin: .5rem;
    }
    
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
    .captcha {
        padding: 5px;
        max-height: 6rem;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    .kino-logo-container img {
        border-radius: 50px;
     }
    .signupTitle {
        font-size: 2rem !important;
    }
    .signup-form {
        margin-top: 0rem;
    }
    .authProviderSignup {
        height: 3.5rem !important;
        width: 3.5rem !important;
    }
    .signup-content {
        height: 100vh; 
        padding: 1rem;
        display: block;
    }
    .signup-container {
        height: auto;
    }
    .container-content {
        height: auto;
    }
    .already-a-member {
        margin: .5rem;
    }
    
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
    .captcha {
        padding: 3px;
        max-height: 6rem;
    }
    .alternateAuth {
        margin: 0px !important;
    }
    
}

@media (min-width: 960px) and (max-width: 1280px) {
    .signup-container {
        overflow-y: clip;
        margin-top: 4%;
        height: auto;
        max-height: none;
    }
    .signup-content {
        height: auto;
        
    }
    .signupTitle {
        font-size: 2rem !important;
    }
    .take-me-in-container {
        height: 100vh;
        flex-basis: 100% !important;
        
    }
    .takeMeInDescription {
        margin: 3%;
    }
    .container-content {
        height: 90vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .already-a-member {
        margin-top: 1%;
    }
    .signup-form {
        margin-top: 0%;
    }
    
}

@media (min-width: 1280px) and (max-width: 1650px) {
    .signup-container {
        overflow-y: clip;
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signup-content {
        height: auto;
        
    }

    .take-me-in-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 93vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signup-form {
        margin-top: 0%;
    }
    .already-a-member {
        margin-top: 1%;
    }
}

@media (min-width: 1650px) and (max-width: 1920px) {
    .signup-container {
        overflow-y: clip;
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signup-content {
        height: auto;
        
    }

    .take-me-in-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 81.2vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        width:50%;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signup-form {
        margin-top: 0%;
    }
    .already-a-member {
        margin-top: 4%;
    }
}

@media (min-width: 1920px) and (min-height: 600px) {
    .signup-section{
        min-height: 0;
        max-height: 100vh;
    }
    .signup-content {
        height: 100vh;
        max-width: 139vh;
        width: 80vw;
        padding: 0;
    }

    .signup-container{
        margin-top:0;
        width: 100%;
        height: 85.2vh;
        min-height: 0;
        max-width: 139vh;
        min-height: 565px;
        max-height: 10000px;
    }

    .take-me-in-container {
        height: 81.2vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 100%;
        margin: 0;
        min-height: 0;
        flex-direction: column;
        max-width: 139vh;
    }
    .sign-up-content {
        height: 81.2vh;
        justify-content: start;
        flex-basis: 100% !important;
    }

    .kino-logo-container {
            width: 100%;
            height: 22vh;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
    }

    .kino-logo-container img {
        border-radius: 50px 0 0 0;
        width: 72vh;
        height: 22vh;
    }
    .sign-up-content #mainTitle {
        color: #032836;
        font-size: 4.2vh;
        text-align: center;
    }
    #mainDescr {
        color: #032836;
        font-size: 2vh;
        text-align: center;
    }
    .sign--alternate-auth {
        gap: 2rem;
        display: flex;
        padding: 8px;
        align-items: center;
        width: 13vw;
        justify-content: center;
    }


    .auth-provider-sign-up-page {
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        border: 3px solid #0e6688;
        text-align: center;
        background: #fff;
        color: #535a60;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 14px 28px rgba(0, 0, 0, .06), 0 10px 10px rgba(0, 0, 0, .11);
        height: 5vh;
        width: 5vh;
    }
    .auth-provider-sign-up-page svg {
        height: 2.8vh;
        width: 2.8vh;
    }

    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signup-form {
        margin-top: 0%;
        width: 80%;
    }
    .socialNetworkTitle {
        text-align: center;
        font-size: 1.6vh;
    }
    .MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        font-size: 2vh;
        font-weight: 900;
    }
    .MuiFormControl-root .MuiOutlinedInput-root {
        position: relative;
        border-radius: 20px;
        font-size: 2vh;
        background-color: white;
    }
    .signup-form .makeStyles-button-56 {
        font-size: 20px;
        border-radius: 20px;
        color: var(--grey);
        max-width: 195px;
        height: 36px;
        box-shadow: none;
        margin-top: .5rem;
        margin-left: 8px;
        margin-right: 8px;
        background-color: var(--darkColor);
    }

    .signup-content .termsDescriptionsMobile{
        color: gray;
        width: 100%;
        font-size: clamp(12px, 1.1vh, 1.1vh);
        margin-top: 5rem;
        text-align: center;
        font-weight: 600;
    }

    .signup-content .makeStyles-takeMeInDescription-42 {
        color: #f29322;
        font-size: 5.6vh;
        margin-top: 3.5rem;
        text-align: center;
        margin-bottom: 8rem;
    }
    .signup-content .makeStyles-takeMeInDescription-43{
        color: #B8C1C5;
        font-size: 3vh;
        text-align: center;
        margin-bottom: 4rem;
    }
    .signup-content .makeStyles-signInButton-44 {
        color: #ffff;
        width: 100%;
        font-size: 20px !important;
        max-width: 195px;
        height: 36px;
        margin-top: 3rem;
        font-weight: 900 !important;
        background-color: #f29322;
    }
    .not-a-member {
        font-size: clamp(16px, 1.5vh, 1.5vh);
        margin-top: 2%;
    }

    .signup-content .makeStyles-ReCAPTCHAContainer-70 {
        transform: scale(0.8);
        transform-origin: center;
        display: flex;
        padding: 0;
        max-height: 7rem;
        justify-content: center;
    }

}

@media (min-width: 1920px) and (min-height: 750px) {
    div .rc-anchor-light{
        background-color:blue;
    }
    .signup-section{
        min-height: 0;
        max-height: 100vh;
    }
    .signup-content {
        height: 100vh;
        max-width: 139vh;
        width: 80vw;
        padding: 0;
    }

    .signup-container{
        margin-top:0;
        width: 100%;
        height: 81.2vh;
        min-height: 0;
        max-width: 139vh;
        min-height: 700px;
        max-height: 10000px;
    }

    .take-me-in-container {
        height: 81.2vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 100%;
        margin: 0;
        min-height: 0;
        flex-direction: column;
        max-width: 139vh;
    }
    .sign-up-content {
        height: 81.2vh;
        justify-content: start;
        flex-basis: 100% !important;
    }

    .kino-logo-container {
        width: 100%;
        height: 22vh;
    }

    .kino-logo-container img {
        border-radius: 50px 0 0 0;
        width: 72vh;
        height: 22vh;
    }
    .sign-up-content #mainTitle {
        color: #032836;
        font-size: 4.2vh;
        text-align: center;
    }
    #mainDescr {
        color: #032836;
        font-size: 2vh;
        text-align: center;
    }
    .sign--alternate-auth {
        gap: 2rem;
        display: flex;
        padding: 8px;
        align-items: center;
        width: 13vw;
        justify-content: center;
    }


    .auth-provider-sign-up-page {
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        border: 3px solid #0e6688;
        text-align: center;
        background: #fff;
        color: #535a60;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 14px 28px rgba(0, 0, 0, .06), 0 10px 10px rgba(0, 0, 0, .11);
        height: 5vh;
        width: 5vh;
    }
    .auth-provider-sign-up-page svg {
        height: 2.8vh;
        width: 2.8vh;
    }

    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signup-form {
        margin-top: 0%;
        width: 80%;
    }
    .socialNetworkTitle {
        text-align: center;
        font-size: 1.6vh;
    }
    .MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        font-size: 2vh;
        font-weight: 900;
    }
    .MuiFormControl-root .MuiOutlinedInput-root {
        position: relative;
        border-radius: 20px;
        font-size: 2vh;
        background-color: white;
    }
    .signup-form .makeStyles-button-56 {
        font-size: 22px;
        border-radius: 20px;
        color: var(--grey);
        max-width: 244px;
        height: 45px;
        box-shadow: none;
        margin-top: 1.5rem;
        margin-left: 8px;
        margin-right: 8px;
        background-color: var(--darkColor);
    }

    .signup-content .termsDescriptionsMobile{
        color: gray;
        width: 100%;
        font-size: clamp(12px, 1.1vh, 1.1vh);
        margin-top: 5rem;
        text-align: center;
        font-weight: 600;
    }

    .signup-content .makeStyles-takeMeInDescription-42 {
        color: #f29322;
        font-size: 5.6vh;
        margin-top: 3.5rem;
        text-align: center;
        margin-bottom: 8rem;
    }
    .signup-content .makeStyles-takeMeInDescription-43{
        color: #B8C1C5;
        font-size: 3vh;
        text-align: center;
        margin-bottom: 4rem;
    }
    .signup-content .makeStyles-signInButton-44 {
        color: #ffff;
        width: 100%;
        font-size: 22px !important;
        max-width: 244px;
        height: 45px;
        margin-top: 3rem;
        font-weight: 900 !important;
        background-color: #f29322;
    }
    .not-a-member {
        font-size: clamp(16px, 1.5vh, 1.5vh);
        margin-top: 2%;
    }
    .signup-content .makeStyles-ReCAPTCHAContainer-70 {
        transform: scale(1);
        transform-origin: center;
        display: flex;
        padding: 1rem 0;
        max-height: 1000px;
        justify-content: center;
    }

}