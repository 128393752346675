.register2 form {
  max-width: 500px;
  margin: 0 auto;
}

.register2 h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.register2 .form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

.register2 p {
  color: #bf1650;
}

.register2 p::before {
  display: inline;
  content: "⚠ ";
}

.MuiPaper-rounded {
  border-radius: 1rem !important;
}

.register2 input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.register2 label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

.register2 button[type="submit"],
.register2 input[type="submit"] {
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

.register2 button[type="submit"]:hover,
.register2 input[type="submit"]:hover {
  background: #bf1650;
}

.register2 button[type="submit"]:active,
.register2 input[type="button"]:active,
.register2 input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.register2 input:disabled {
  opacity: 0.4;
}

.register2 input[type="button"]:hover {
  transition: 0.3s all;
}

.register2 button[type="submit"],
.register2 input[type="button"],
.register2 input[type="submit"] {
  -webkit-appearance: none;
}

.register2 {
  max-width: 600px;
  margin: 0 auto;
}

.register2 button[type="button"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.register2 hr {
  margin-top: 30px;
}

.register2 button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.add-payment-method-modal {
  /* width: 450px; */
  margin: auto;
  text-align: center;
}

.add-payment-method-modal form {
  width: 100%;
}

.add-payment-method-modal Button {
  color: "#032836";
  margin-top: 1rem;
}

.open-ids {
  width: 236px;
  margin: 0 auto;
}

.auth-provider {
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #d6d9dc;
  text-align: center;
  background: #fff;
  color: #535a60;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  min-width: 4rem;
}

.auth-provider svg {
  /* margin-right: 5px; */
  height: 2rem;
  width: 2rem;
}

.auth-provider-sign-up-page {
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid #0e6688;
  text-align: center;
  background: #fff;
  color: #535a60;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:rgb(0 0 0 / 6%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px;
  height: 4rem;
  width: 4rem;
}

.auth-provider-sign-up-page svg {
  /* margin-right: 5px; */
  height: 2.5rem;
  width: 2.5rem;
}

.auth-provider-sign-in-page {
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid #0e6688;
  text-align: center;
  background: #fff;
  color: #535a60;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:rgb(0 0 0 / 6%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px;
  height: 4rem;
  width: 4rem;
}

.auth-provider-sign-in-page svg {
  /* margin-right: 5px; */
  height: 2rem;
  width: 2rem;
}

/* @media (max-width: 960px) {
  .auth-provider-sign-up-page {
    min-height: 3rem;
    min-width: 3rem;
  }

  .auth-provider-sign-up-page svg {
    height: 2rem;
    width: 2rem;
  }
} */

.google-login {
  color: #535a60;
  border-color: #d6d9dc;
}
.google-login-sign-up-page {
  color: #535a60;
  border-color: #34A853;
}

.facebook-login {
  color: #fff;
  border-color: transparent;
  border: 1px solid #d6d9dc;
}

.facebook-login-sign-up-page {
  color: #fff;
  border-color: transparent;
  border: 3px solid #0e6688;
  box-shadow: rgb(0 0 0 / 6%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px
}

.email-login-sign-up-page {
  color: #fff;
  border-color: transparent;
  border: 3px solid #0e6688;
  box-shadow: rgb(0 0 0 / 6%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px
}

.facebook-login svg {
  height: 2rem !important;
}

.facebook-login-sign-up-page svg {
  height: 3rem !important;
}

@media (max-width: 960px) {
  .facebook-login svg {
    height: 4rem !important;
  }
  .facebook-login-sign-up-page svg {
    height: 4rem !important;
  }
}

.svg-icon {
  vertical-align: middle;
  padding-bottom: 2px;
}

/* styles for 6-digit input */
.react-code-input {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px !important;
}

.react-code-input input {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
  padding-left: 0 !important;
  text-align: center;
  border-radius: 4px !important;
  box-shadow: none !important;
  width: 55px !important;
  height: 70px !important;
  border: 2px solid #dfe7f2 !important;
}

.react-code-input input:focus {
  border-color: #26a2b8 !important;
  box-shadow: 0px 0px 2px #26a2b8 inset !important;
}
.react-code-input input:focus-visible {
  outline: none !important;
}

.disabled:disabled {
  background-color: #f29322 !important;
  opacity: 0.8;
  color: white !important;
}

.MuiButton-contained .Mui-disabled {
  background-color: #f29322 !important;
}