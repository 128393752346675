.text-center {
    text-align: center;
}

.flex{
    display: flex;
}

.flex-column{
    flex-direction: column;
}

.align-center{
    align-items: center;
}
.align-end{
    align-items: flex-end;
}
.w-full{
    width: 100%;
}

.mt-1{
    margin-top: 0.5rem;
}
.mt-20{
    margin-top: 20px;
}
.ls-75 {
    letter-spacing: .75px;
}
.text-white{
    color: white;
}
.text-gray{
    color: #D9D9D9;
}
.text-orange{
    color: #FF824D 
}
.text-blue{
    color: var(--darkColor)
}
.font-20{
    font-size: 20px !important;
}
.font-sm{
    font-size: 14px;
}
.text-shadow{
    text-shadow: 0px 0px 5px #52B7DD;
}
.flex-between{
    justify-content: space-between;
}

.signup-steps{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.signup-steps .step{
    pointer-events: none;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 25px;
    background-color: #fff;
    color: black;
    font-size: 15px;
    font-weight: '500';
    border: 1px solid #f29322;
}

.signup-steps .step.active{
    background-color: #f29322;
    color: white;
}

.signup-steps .line{
    width: 100%;
    height: 4px;
    background-color: #f29322;
    position: absolute;
    top: 18px;
    z-index: 0;
}

@media (min-width: 1650px) {
    .paymentLogoSection {
        position: absolute;
        bottom: 1%;
    }
    .logoSection1 {
        margin-left: 5%;
    }
    .logoSection2 {
        margin-right: 5%;
    }
}

@media (min-width: 960px) and (max-width: 1280px) {
    .paymentLogoSection {
        position: absolute;
        bottom: 1%;
    }
    .logoSection1 {
        margin-left: 5%;
    }
    .logoSection2 {
        margin-right: 5%;
    }
}

@media (min-width: 1280px) and (max-width: 1650px) {
    .paymentLogoSection {
        position: absolute;
        bottom: 1%;
    }
    .logoSection1 {
        margin-left: 5%;
    }
    .logoSection2 {
        margin-right: 5%;
    }
}

@media only screen and (max-width: 600px) {
    .paymentLogoSection {
        display: block;
        margin: auto;
        max-width: 50%;
        text-align: center;
    }
    .logoSection2 {
        display: block;
    }
    .sponsorMessage2 {
        display: block !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
    .paymentLogoSection {
        display: block;
        margin: auto;
        max-width: 40%;
        text-align: center;
    }
    .logoSection2 {
        display: block;
    }
    .sponsorMessage2 {
        display: block !important;
    }
}